<template>
  <div class="home-container">
    <div class="info-container">
      <PowerBI :url="url" />
    </div>
  </div>
</template>

<script>
import PowerBI from './PowerBI';
import statisticsMixin from './statistics_mixin';
export default {
  components: { PowerBI },
  mixins: [statisticsMixin],
  computed: {
    url() {
      return this.getUrlForCurrentLanguage('powerBI.CustomerSatisfaction.URL');
    }
  }
};
</script>
